@font-face {
  font-family: "gotham";
  src: url("./fonts/gotham_font/Gotham-Medium.otf") format("opentype");
  font-weight: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "gotham" !important;
  /* font-family: "Arial Black", Gadget, sans-serif; */
  color: "#515151";
  font-size: "50px";
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-color: "white";
}
